<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 2000;
export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    },
    label: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        color: this.color,
        tooltip: {
          // trigger: "axis",
          textStyle: {
            color: "#fff",
            fontSize: 16,

            fontFamily: "DBHeavent",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            var output = params.name + "<br/>";
            output += params.marker + params.value + " ราย";
            let total = 0;
            this.data.forEach((row) => {
              total += row.value;
            });
            output +=
              "<span class='pd-l-6'>" +
              this.totalPercent(params.value, total) +
              "%</span>";

            return output;
          },
        },
        series: [
          {
            type: "treemap",
            width: "100%",
            height: "100%",
            breadcrumb: {
              show: false,
            },
            nodeClick: false,
            roam: false,
            label: {
              formatter: (params) => {
                var output = params.name + "\n" + params.value + " ราย";
                return output;
              },
              fontSize: 16,
              fontFamily: "DBHeavent",
              lineHeight: 12,
              padding: [0, 0, 0, 0],
            },
            data: this.data,
          },
        ],
      });
    },
    getTooltipFormatter(mode) {
      return "asdasdasd";
    },
  },
};
</script>