<template>
  <el-row :gutter="20" class="row-custom-dashboard">
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">นำเสนอ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataOffer"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataOffer.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลนำเสนอ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">สนใจ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataInterest"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataInterest.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลที่สนใจ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สถานะอนุมัติ</h2>
            <BarChartsGroup
                :width="dataStatusApprove.length  > 9 && widthMax < 1600 ? `1500px` : ''"
:showName="true"

              :data="dataStatusApprove"
              :height="'300px'"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataStatusApprove.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลสถานะอนุมัติ</h2>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import BarCharts from "@/charts/BarCharts";
import BarChartsGroup from "@/charts/BarChartsGroup";
import { colorChart } from "@/colors/color.json";

export default {
  props: ["fetchChart", "dataFetch"],
  components: {
    BarChartsGroup,
    BarCharts,
  },
  data() {
    return {
      color: ["#FAC858", "#5470C6", "#FF9900"],
      colorChart: colorChart,
      dataOffer: [],
      dataInterest: [],
      dataStatusApprove: [],
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.setDataOffers();
      this.setDataInterest();
      this.setDataApprove();
    },
    setDataOffers() {
      this.dataOffer = [];
      const result = Object.values(this.dataFetch.businessSizeOffer).some(
        (e) => e != 0
      );
      let total = 0;
      Object.values(this.dataFetch.businessSizeOffer).forEach((obj) => {
        total += obj;
      });
      if (result) {
        let setObj = [
          {
            data: Object.values(this.dataFetch.businessSizeOffer),
            name: Object.keys(this.dataFetch.businessSizeOffer),
            total: total,
          },
        ];
        this.dataOffer = setObj;
      }
    },
    setDataInterest() {
      this.dataInterest = [];
      const result = Object.values(this.dataFetch.businessSizeInterest).some(
        (e) => e != 0
      );
      let total = 0;
      Object.values(this.dataFetch.businessSizeInterest).forEach((obj) => {
        total += obj;
      });
      if (result) {
        let setObj = [
          {
            data: Object.values(this.dataFetch.businessSizeInterest),
            name: Object.keys(this.dataFetch.businessSizeInterest),
            total: total,
          },
        ];
        this.dataInterest = setObj;
      }
    },
    setDataApprove() {
      this.dataStatusApprove = [];
      if (this.dataFetch.statusApprove.length > 0) {
        this.dataFetch.statusApprove.forEach((row) => {
          let sizeData = "";
          if (row.size.toLowerCase() == "s") {
            sizeData = "Small";
          } else if (row.size.toLowerCase() == "m") {
            sizeData = "Medium";
          } else if (row.size.toLowerCase() == "l") {
            sizeData = "Large";
          }
          let setObj = {
            product: sizeData,
            อนุมัติ: row.approve,
            อนุมัติมีเงื่อนไข:
              row.apporveHaveCondition == null ? 0 : row.apporveHaveCondition,
            ไม่อนุมัติ: row.reject,
          };
          if (
            row.approve > 0 ||
            row.apporveHaveCondition > 0 ||
            row.reject > 0
          ) {
            this.dataStatusApprove.push(setObj);
          }
        });
        // let setObj = this.dataFetch.statusApprove.map((row) => ({
        //   product: row.size,
        //   อนุมัติ: row.approve,
        //   อนุมัติมีเงื่อนไข: row.apporveHaveCondition,
        //   ไม่อนุมัติ: row.reject,
        // }));
        // this.dataStatusApprove = setObj;
      }
    },
  },
};
</script>