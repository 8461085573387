<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20" class="mg-t-5 row-custom-dashboard">
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">นำเสนอ</h2>
              <h2 class="mg-0" v-if="companiesOffers.length > 0">
                รวม {{ sumTotal(companiesOffers) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="companiesOffers"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="companiesOffers.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">สนใจ</h2>
              <h2 class="mg-0" v-if="companiesInterests.length > 0">
                รวม {{ sumTotal(companiesInterests) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="companiesInterests"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="companiesInterests.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
          </div>
        </el-col>
        <el-col :span="24" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <h2 class="mg-0">สถานะอนุมัติ</h2>
            <div style="width: 100%; overflow-x: auto">
                <!-- :width="widthMax < 1600 ? `1900px` : ''" -->

              <BarChartsGroup
                :width="dataStatusApprove.length  > 9 && widthMax < 1600 ? `1500px` : ''"
:showName="true"
                pageType="lead"
                :data="dataStatusApprove"
                :height="'300px'"
                :color="color"
                :fetchChart="fetchChart"
                v-if="dataStatusApprove.length > 0 && sumApprove > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลสถานะอนุมัติ</h2>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import TreeMap from "@/charts/TreeMap";
import BarChartsGroup from "@/charts/BarChartsGroup";
import { colorChart } from "@/colors/color.json";

export default {
  props: ["fetchChart", "dataFetch"],
  components: {
    TreeMap,
    BarChartsGroup,
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  data() {
    return {
      widthMax: window.innerWidth,
      color: ["#3598FF", "#FF646A", "#38D85B"],
      colorChart: colorChart,
      dataStatusApprove: [],
      companiesOffers: [],
      sumApprove: 0,
      companiesInterests: [],
    };
  },
  methods: {
    setData() {
      this.setDataCompaniesOffers();
      this.setDataCompaniesInterests();
      this.setDataApprove();
      // this.setDataReasonCA();
      // this.setDataReasonLOS();
    },
    sumTotal(dataSum) {
      let total = 0;
      dataSum.forEach((row) => {
        total += Number(row.value);
      });
      return total;
    },
    setDataCompaniesOffers() {
      this.companiesOffers = [];
      let arrIndex = [];
      this.dataFetch.companiesOffers.forEach((e) => {
        let checkIndexOf = arrIndex.indexOf(e.gradeName);
        if (checkIndexOf < 0) {
          arrIndex.push(e.gradeName);
        }
      });
      arrIndex.forEach((name) => {
        let dataNow = this.dataFetch.companiesOffers.filter(
          (c) => c.gradeName == name
        );
        this.companiesOffers.push({ name: name, value: dataNow.length });
      });
      // console.log(this.companiesOffers);
    },
    setDataCompaniesInterests() {
      this.companiesInterests = [];
      let arrIndex = [];
      this.dataFetch.companiesInterests.forEach((e) => {
        let checkIndexOf = arrIndex.indexOf(e.gradeName);
        if (checkIndexOf < 0) {
          arrIndex.push(e.gradeName);
        }
      });
      arrIndex.forEach((name) => {
        let dataNow = this.dataFetch.companiesInterests.filter(
          (c) => c.gradeName == name
        );
        this.companiesInterests.push({
          name: name,
          value: dataNow.length,
        });
      });
      // console.log(this.companiesInterests);
    },
    setDataApprove() {
      this.dataStatusApprove = [];
      if (this.dataFetch.statusApprove.some((e) => e.statusApprove != 3)) {
        let arrIndex = [];
        this.dataFetch.statusApprove.forEach((e) => {
          let checkIndexOf = arrIndex.indexOf(e.gradeName);
          if (checkIndexOf < 0) {
            arrIndex.push(e.gradeName);
          }
        });

        arrIndex.forEach((name) => {
          let sumTotal = 0;

          let dataNow = this.dataFetch.statusApprove.filter(
            (c) => c.gradeName == name
          );
          let approve = dataNow.filter(
            (c) => c.gradeName == name && c.statusApprove == 1
          );
          let approveIf = dataNow.filter(
            (c) => c.gradeName == name && c.statusApprove == 0
          );
          let reject = dataNow.filter(
            (c) => c.gradeName == name && c.statusApprove == 2
          );
          if (
            approve.length > 0 ||
            approveIf.length > 0 ||
            reject.length > 0
          ) {
            this.dataStatusApprove.push({
              product: name,
              อนุมัติ: approve.length,
              อนุมัติมีเงื่อนไข: approveIf.length,
              ไม่อนุมัติ: reject.length,
            });
          }
          sumTotal = approve.length + approveIf.length + reject.length;
          this.sumApprove += sumTotal;
        });
        // let setObj = this.dataFetch.statusApprove.map((row) => ({
        //   product: row.gradeName,
        //   อนุมัติ: row.approve,
        //   รออนุมัติ: row.pending,
        //   ไม่อนุมัติ: row.reject,
        // }));
        // let sumTotal = 0;
        // this.dataFetch.statusApprove.forEach((e) => {
        //   sumTotal += e.approve;
        //   sumTotal += e.pending;
        //   sumTotal += e.reject;
        // });
        // this.sumApprove = sumTotal;
        // this.dataStatusApprove = setObj;
      }
    },
  },
};
</script>