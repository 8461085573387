<template>
  <el-row :gutter="20" class="row-custom-dashboard">
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">นำเสนอ</h2>
            <div style="width: 100%; overflow-x: auto">
              <!-- :width="widthMax < 1200 ? `1120px` : ''" -->
              <BarCharts
                :showName="false"
                :barWidth="`25px`"
                :label="dataOffer[0].name"
                :height="'300px'"
                :data="dataOffer[0].data"
                :fetchChart="fetchChart"
                v-if="dataOffer.length > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สนใจ</h2>
            <div style="width: 100%; overflow-x: auto">
              <!-- :width="widthMax < 1200 ? `1120px` : ''" -->
              <BarCharts
                :showName="false"
                :barWidth="`25px`"
                :label="dataInterest[0].name"
                :height="'300px'"
                :data="dataInterest[0].data"
                :fetchChart="fetchChart"
                v-if="dataInterest.length > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลที่สนใจ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">สถานะอนุมัติ</h2>
            <div style="width: 100%; overflow-x: auto">
              <!-- <BarCharts
                :width="widthMax < 1200 ? `1120px` : ''"
                :barWidth="`25px`"
                :label="dataApprove[0].name"
                :height="'300px'"
                :data="dataApprove[0].data"
                :fetchChart="fetchChart"
                v-if="dataApprove.length > 0"
              /> -->
              <BarChartsGroup
                :data="dataApprove"
                :width="dataApprove.length  > 9 && widthMax < 1600 ? `1500px` : ''"

                :barWidth="`25px`"
                :height="'300px'"
                :color="color"
                :fetchChart="fetchChart"
                v-if="dataApprove.length > 0"
                pageType="tabIndustry"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลสถานะอนุมัติ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>

  </el-row>
</template>
<script>
import BarCharts from "@/charts/BarChartsNormal";
import BarChartsGroup from "@/charts/BarChartsGroup";
import { colorChart } from "@/colors/color.json";

export default {
  components: {
    BarCharts,
    BarChartsGroup,
  },
  props: ["fetchChart", "dataFetch"],
  data() {
    return {
      colorChart: colorChart,
      dataOffer: [],
      dataInterest: [],
      dataApprove: [],
      widthMax: window.innerWidth,
      color: ["#FAC858", "#5470C6", "#FF9900"],
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.setDataOffers();
      this.setDataInterest();
      this.setDataApprove();
    },
    setDataOffers() {
      this.dataOffer = [];
      if (this.dataFetch.offers.some((e) => e.count != 0)) {
        let setObj = [
          {
            data: [],
            name: [],
          },
        ];
        this.dataFetch.offers.sort((a, b) => {
          let modifier = -1;
          if (a.count < b.count) return -1 * modifier;
          if (a.count > b.count) return 1 * modifier;
          return 0;
        });
        this.dataFetch.offers.forEach((e) => {
          if (e.count != 0) {
            setObj[0].data.push(e.count);
            setObj[0].name.push(e.label.replace("ทางการ", "ทางการ\n"));
          }
        });
        this.dataOffer = setObj;
        // this.dataOffer.map((e) => {
        //   if (e.name == "ของขวัญ / - ของเล่น") {
        //     e.name.replace("ของขวัญ", "สวัสดีจ้า");
        //   }
        // });
        // this.dataOffer.forEach((row) => {
        //   row.name.forEach(nameRow => {
        //     console.log(nameRow);
        //     nameRow = nameRow.replace("ของขวัญ", "สวัสดีจ้า")
        //   });
        // });
      }
    },
    setDataInterest() {
      this.dataInterest = [];
      if (this.dataFetch.interest.some((e) => e.count != 0)) {
        let setObj = [
          {
            data: [],
            name: [],
          },
        ];
        this.dataFetch.interest.sort((a, b) => {
          let modifier = -1;
          if (a.count < b.count) return -1 * modifier;
          if (a.count > b.count) return 1 * modifier;
          return 0;
        });
        this.dataFetch.interest.forEach((e) => {
          if (e.count != 0) {
            setObj[0].data.push(e.count);
            setObj[0].name.push(e.label.replace("ทางการ", "ทางการ\n"));
          }
        });
        this.dataInterest = setObj;
      }
    },
    setDataApprove() {
      // this.dataApprove = [];
      // if (this.dataFetch.approve.some((e) => e.count != 0)) {
      // let setObj = [
      //   {
      //     data: this.dataFetch.approve.map((e) => e.count),
      //     name: this.dataFetch.approve.map((e) =>
      //       e.label.replace("ทางการ", "ทางการ\n")
      //     ),
      //   },
      // ];
      // this.dataApprove = setObj;
      // }

      this.dataApprove = [];
      let setObj = [];
      let total = 0;
      this.dataFetch.approve.forEach((row, index) => {
        if (row.approve > 0 || row.apporveHaveCondition > 0 || row.notApprove > 0) {
          setObj.push({
            product: row.label.replace("ทางการ", "ทางการ\n"),
            อนุมัติ: row.approve,
            อนุมัติมีเงื่อนไข: row.apporveHaveCondition,
            ไม่อนุมัติ: row.notApprove,
          });
        }
        total += row.approve + row.apporveHaveCondition + row.notApprove;
      });
      if (total == 0) {
        this.dataApprove = [];
      } else {
        this.dataApprove = setObj;
      }
    },
   
  },
};
</script>