<template>
  <DefultTempalte
    @reRenderChart="renderChart"
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="12" :offset="0">
          <h1>ภาพรวมสินเชื่อ</h1>
        </el-col>
        <!-- <el-col :span="12" :offset="0" class="text-right">
          <el-button
            type="primary"
            @click="exportDashboard"
            :loading="loadingExport"
            id="btnExportCSV"
          >
            Export</el-button
          >
        </el-col> -->
      </el-row>
      <el-card shadow="never">
        <div class="set-form">
          <el-form :model="filters" ref="ruleForm" label-position="top">
            <el-row :gutter="20">
              <el-col :span="12" :lg="6">
                <el-form-item label="ปี" class="padding-less">
                  <el-select
                    id="filtersYear"
                    @change="getDashboard()"
                    v-model="filters.year"
                    clearable
                    placeholder="ปีทั้งหมด"
                  >
                    <el-option
                      v-for="(item, index) in yearAll"
                      :key="index"
                      :label="item"
                      :value="Number(item)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="6">
                <el-form-item label="ขนาดธุรกิจ" class="padding-less">
                  <el-select
                    id="filtersBusinessSize"
                    @change="getDashboard()"
                    v-model="filters.businessSize"
                    clearable
                    placeholder="ขนาดธุกิจทั้งหมด"
                  >
                    <el-option label="ธุรกิจขนาดเล็ก (S)" value="S">
                    </el-option>
                    <el-option label="ธุรกิจขนาดกลาง (M)" value="M">
                    </el-option>
                    <el-option label="ธุรกิจขนาดใหญ่ (L)" value="L">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="6">
                <el-form-item label="ประเภทอุตสาหกรรม" class="padding-less">
                  <el-select
                    id="filtersCateIndustryType"
                    label="ประเภทอุตสาหกรรม"
                    @change="getDashboard()"
                    v-model="filters.cateIndustryType"
                    clearable
                    filterable
                    placeholder="อุตสาหกรรมทั้งหมด"
                  >
                    <el-option
                      v-for="item in industryType"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="6">
                <el-form-item
                  label="หน่วยงานที่เป็นสมาชิก"
                  class="padding-less"
                >
                  <el-select
                    id="filtersMemberOf"
                    @change="getDashboard()"
                    v-model="filters.memberOf"
                    multiple
                    filterable
                    default-first-option
                    popper-class="custom-select"
                    placeholder="สมาชิกของหน่วยงานทั้งหมด"
                  >
                    <el-option
                      v-for="item in memberOfOrganize"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <el-row :gutter="20">
        <el-col :span="24" :offset="0">
          <h1>
            <span v-if="criterionName !== ''">
              {{ criterionName }}
            </span>
          </h1>
        </el-col>
        <el-col :span="24">
          <el-tabs
            type="card"
            class="tab-dashboard"
            @tab-click="handleClick"
            v-model="activeNameTab"
          >
            <el-tab-pane label="เกณฑ์ Lead" name="1"></el-tab-pane>
            <el-tab-pane label="Product Program" name="2"></el-tab-pane>
            <el-tab-pane label="ขนาดธุรกิจ" name="3"></el-tab-pane>
            <el-tab-pane label="ประเภทอุตสาหกรรม" name="4"></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div :class="loading ? 'mg-t-1' : ''">
        <div
          v-loading="loading"
          element-loading-text="กำลังโหลดข้อมูล..."
          element-loading-spinner="el-icon-loading"
        >
          <div v-if="!loading && !loadingData">
            <TabLead
              v-if="activeNameTab == '1'"
              :fetchChart="render"
              :dataFetch="dataDashboard.objLead.lead"
            />
            <TabProduct
              v-else-if="activeNameTab == '2'"
              :fetchChart="render"
              :dataFetch="dataDashboard.obj"
            />
            <TabBusiness
              v-else-if="activeNameTab == '3'"
              :fetchChart="render"
              :dataFetch="dataDashboard.obj"
            />
            <TabIndustry
              v-else-if="activeNameTab == '4'"
              :dataFetch="dataDashboard.obj"
              :fetchChart="render"
            />
          </div>
        </div>
      </div>
    </div>
  </DefultTempalte>
</template>
<script>
import BarCharts from "@/charts/BarCharts";
import PieChart from "@/charts/PieCharts";
import DefultTempalte from "@/template/DefaultTemplate";
import TabLead from "@/components/dashboard/credit/TabLead";
import TabProduct from "@/components/dashboard/credit/TabProduct";
import TabBusiness from "@/components/dashboard/credit/TabBusiness";
import TabIndustry from "@/components/dashboard/credit/TabIndustry";
import { HTTP } from "@/service/axios";
import moment from "moment";

export default {
  components: {
    DefultTempalte,
    BarCharts,
    PieChart,
    TabLead,
    TabProduct,
    TabBusiness,
    TabIndustry,
  },
  computed: {
    _token() {
      return this.$store.state._token;
    },
    memberOfOrganize() {
      return this.$store.state.memberOfOrganize;
    },
    industryType() {
      return this.$store.state.industryType;
    },
    industryType() {
      return this.$store.state.industryType;
    },
    yearAll() {
      let year = new Date().getFullYear() + 543;
      let yearStart = 2564;
      let returnYear = [];
      for (let i = 0; i < year + 1 - yearStart; i++) {
        returnYear.push((year - i).toString());
      }

      let month = Number(moment().format("M"));
      if (month == 12) {
        returnYear.unshift((year + 1).toString());
      }
      return returnYear;
    },
    criterionName() {
      return this.$store.state.nameDashboardCredit;
    },
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "dashboard-credit");
    this.getDashboard();
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_BASE_URL,
      loadingExport: false,
      filters: {
        year: "",
        businessSize: "",
        cateIndustryType: "",
        memberOf: [],
        criterionId: Number(this.$route.params.id),
      },
      activeNameTab: "1",
      dataDashboard: [],
      render: false,
      loading: false,
      loadingData: true,
    };
  },
  methods: {
    exportDashboard() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`dashboard/export/credit`)
        .then((res) => {
          if (res.data.success) {
            var file_path =
              this.urlBase + "system/download/" + res.data.filename;
            // console.log(file_path);
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
    renderChart() {
      this.render = !this.render;
    },
    async getDashboard() {
      let pathUrl = "";
      if (this.activeNameTab == "1") {
        pathUrl = "dashboard/credit/lead";
      } else if (this.activeNameTab == "2") {
        pathUrl = "dashboard/credit/product/program";
      } else if (this.activeNameTab == "3") {
        pathUrl = "dashboard/credit/businessize";
      } else if (this.activeNameTab == "4") {
        pathUrl = "dashboard/credit/industry";
      }
      let obj = {
        year: this.filters.year != "" ? this.filters.year : 0,
        businessSize: this.filters.businessSize,
        cateIndustryType: this.filters.cateIndustryType.toString(),
        memberOf: this.filters.memberOf,
        criterionId: this.filters.criterionId,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(pathUrl, obj)
        .then((res) => {
          if (res.data.success) {
            this.$store.commit(
              "SET_NAME_DASHBOARD_CREDIT",
              res.data.criterion.criterionName
            );
            this.loading = false;
            if (this.activeNameTab == "1") {
              res.data.objLead.lead.statusApprove.sort((a, b) => {
                let modifier = 1;
                if (a.gradeNo < b.gradeNo) return -1 * modifier;
                if (a.gradeNo > b.gradeNo) return 1 * modifier;
                return 0;
              });
            }
            this.dataDashboard = res.data;
            this.loadingData = false;
          }
          this.renderChart();
          return res;
        })
        .catch((e) => {
          this.loadingData = false;

          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        });
      return res;
    },
    handleClick(tab) {
      this.loading = true;
      this.activeNameTab = tab.name;
      this.getDashboard();
    },
  },
};
</script>