<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20" class="mg-t-5 row-custom-dashboard">
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">นำเสนอ</h2>
              <h2 class="mg-0" v-if="dataOffers.length > 0">
                รวม {{ sumTotal(dataOffers) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="dataOffers"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="dataOffers.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลนำเสนอ</h2>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <div class="is-flex js-between">
              <h2 class="mg-0">สนใจ</h2>
              <h2 class="mg-0" v-if="dataInterest.length > 0">
                รวม {{ sumTotal(dataInterest) }} ราย
              </h2>
            </div>
            <TreeMap
              :data="dataInterest"
              :color="colorChart"
              :fetchChart="fetchChart"
              v-if="dataInterest.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลที่สนใจ</h2>
          </div>
        </el-col>
        <el-col :span="24" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <h2 class="mg-0">สถานะอนุมัติ</h2>
            <div style="width: 100%; overflow-x: auto">
                <!-- :width="widthMax < 1600 ? `1900px` : ''" -->
              <BarChartsGroup
                :width="dataStatusApprove.length  > 9 && widthMax < 1600 ? `1500px` : ''"
          
                pageType="tabIndustry"
                :data="dataStatusApprove"
                :height="'300px'"
                :color="color"
                :fetchChart="fetchChart"
                v-if="dataStatusApprove.length > 0 && sumApprove > 0"
              />
              <h2 v-else class="text-center">ไม่มีข้อมูลสถานะอนุมัติ</h2>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import TreeMap from "@/charts/TreeMap";
import BarChartsGroup from "@/charts/BarChartsGroup";
import { colorChart } from "@/colors/color.json";

export default {
  props: ["fetchChart", "dataFetch"],
  components: {
    TreeMap,
    BarChartsGroup,
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  data() {
    return {
      widthMax: window.innerWidth,
      color: ["#3598FF", "#FF646A", "#38D85B"],
      colorChart: colorChart,
      dataOffers: [],
      dataInterest: [],
      dataStatusApprove: [],
      sumApprove: 0,
    };
  },
  methods: {
    setData() {
      this.setDataOffers();
      this.setDataInterest();
      this.setDataApprove();
    },
    sumTotal(dataSum) {
      let total = 0;
      dataSum.forEach((row) => {
        total += Number(row.value);
      });
      return total;
    },
    setDataOffers() {
      this.dataOffers = [];
      if (this.dataFetch.offers.some((e) => e.count != 0)) {
        let response = [];

        this.dataFetch.offers.forEach((row) => {
          var findByName = response.find((e) => e.name == row.creditName);
          if (findByName == undefined || findByName == null) {
            response.push({
              value: row.count,
              name: row.creditName,
            });
          } else {
            findByName.value += row.count;
          }
        });
        this.dataOffers = response;
      }
    },
    setDataInterest() {
      this.dataInterest = [];
      if (this.dataFetch.interest.some((e) => e.count != 0)) {
        let response = [];
        this.dataFetch.interest.forEach((row) => {
          var findByName = response.find((e) => e.name == row.creditName);
          if (findByName == undefined || findByName == null) {
            response.push({
              value: row.count,
              name: row.creditName,
            });
          } else {
            findByName.value += row.count;
          }
        });
        this.dataInterest = response;
      }
    },
    setDataApprove() {
      this.dataStatusApprove = [];
      if (this.dataFetch.statusApprove.length > 0) {
        let response = [];
        this.dataFetch.statusApprove.forEach((row) => {
          var findByName = response.find((e) => e.product == row.creditName);
          if (findByName == undefined || findByName == null) {
            if (
              row.status.approve > 0 ||
              row.status.apporveHaveCondition > 0 ||
              row.status.reject > 0
            ) {
              response.push({
                อนุมัติ: row.status.approve,
                อนุมัติมีเงื่อนไข: row.status.apporveHaveCondition,
                ไม่อนุมัติ: row.status.reject,
                product: row.creditName,
              });
            }
          } else {
            (findByName.อนุมัติ += row.status.approve),
              (findByName.อนุมัติมีเงื่อนไข += row.status.apporveHaveCondition),
              (findByName.ไม่อนุมัติ += row.status.reject);
          }
          this.sumApprove +=
            row.status.approve +
            row.status.apporveHaveCondition +
            row.status.reject;
        });
        this.dataStatusApprove = response;
      }
    },
  },
};
</script>